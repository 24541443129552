import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { Pager } from '../utils/styles'
import Img from 'gatsby-image'
import Seo from '../components/Seo'
import { mq, colors, PageHeadings } from '../utils/styles'
import Episode from '../components/Episode'

const Post = styled('div')`
  display: flex;
  justify-content: center;
  ${mq({
    'flex-wrap': ['wrap', 'nowrap', 'nowrap', 'nowrap'],
    'padding-bottom': ['0rem', '0.5rem', '0.5rem', '0.5rem'],
  })};
`
const PostContent = styled('div')`
  ${mq({
    'padding-left': ['0rem', '1rem', '1rem', '1rem'],
  })};
`

const ImagePost = styled('div')`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
const EpisodeText = styled('div')`
  display: block;
  padding-bottom: 1rem;
`

class Listen extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          type="page"
          title="Episodes"
          description={this.props.data.site.siteMetadata.siteDescription}
          path={`/episodes/`}
        />
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 1rem;
          `}
        >
          {this.props.data.allFeedPodcastFeed.edges.map(({ node }) => {
            return (
              <div
                className={css`
                  max-width: 24rem;
                  padding-right: 1rem;
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                `}
                key={node.title}
              >
                <ImagePost onClick={() => navigate(node.fields.slug)}>
                  {console.log('node', node)}
                  <Img
                    alt={`${node.title} image`}
                    fixed={node.fields.localImage.childImageSharp.fixed}
                  />
                </ImagePost>
                <Episode
                  hidePlayer={true}
                  smaller={true}
                  episode={node}
                  siteUrl={this.props.data.site.siteMetadata.siteUrl}
                />
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default Listen

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
        siteDescription
        siteUrl
      }
    }

    allFeedPodcastFeed(sort: {fields: isoDate, order: DESC}) {
    edges {
      node {
        content
        contentSnippet
        link
        pubDate
        title
        itunes {
          duration
          image {
            attrs {
              href
            }
          }
        }
        
        fields{
            slug
            localImage{
              relativePath
              childImageSharp{
                fixed(width: 200, height: 200) {
                  ...GatsbyImageSharpFixed
                  }
              }
            }
          }
        id
      }
    }
  }

    art: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "Kudakwashe_Samkange.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
          }
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "pages"}, absolutePath: {regex:"/\\.(gif|jpg|jpeg|tiff|png)$/"}}) {
      edges {
        node {
          relativePath
          childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          # cropFocus: CENTER
          fixed(width: 200, height: 180) {
            ...GatsbyImageSharpFixed
           }
          }
        }
      }
    }
  }
`

// allEpisodes(sort: {fields: [date], order: DESC}) {
//   edges{
//     node{
//       title
//       date(formatString: "MMMM D, YYYY")
//       description
//       fields{
//         slug
//         file{
//           publicURL
//         }
//       }
//     }
//   }
// }
