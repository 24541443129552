import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { Pager } from '../utils/styles'
import Img from 'gatsby-image'
import Seo from '../components/Seo'
import { mq, colors, PageHeadings } from '../utils/styles'
import Player from './Player'

const Post = styled('div')`
  display: flex;
  justify-content: center;
  ${mq({
    'flex-wrap': ['wrap', 'nowrap', 'nowrap', 'nowrap'],
    'padding-bottom': ['0rem', '0.5rem', '0.5rem', '0.5rem'],
  })};
`
const EpisodeText = styled('div')`
  display: block;
  padding-bottom: 0.2rem;
`

const Title = styled(Link, { shouldForwardProp: prop => prop !== 'smaller' })`
  text-decoration: none;
  h2 {
    margin-bottom: 0.2rem;
    ${props => (props.smaller ? `font-size: ${rhythm(0.9)};` : '')}
  }
`

class Episode extends React.Component {
  render() {
    const episode = this.props.episode
    // smaller
    return (
      <div>
        <EpisodeText>
          <Title
            smaller={this.props.smaller}
            to={episode.fields.slug}
            state={{ track: true }}
          >
            <h2>{episode.title}</h2>
          </Title>
          <small>{episode.rawDate}</small>
          <p>
            {episode.content
              .replace(/&nbsp;/g, '')
              .replace(/<(?:.|\n)*?>/gm, '')
              .substring(0, 100)}
            ...
          </p>
        </EpisodeText>
        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          `}
        >
          {!this.props.hidePlayer && (
            <Player width="100%" link={episode.link} />
          )}
        </div>
      </div>
    )
  }
}

export default Episode
